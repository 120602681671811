import { parseEther } from 'ethers/lib/utils';
import { Chain, chain, erc20ABI } from 'wagmi';
import dynamicNftsABI from '../abis/dynamicNft.abi.json';
import dynamicNftSaleABI from '../abis/dynamicNftSale.abi.json';
import fancyBearsABI from '../abis/fancyBears.abi.json';
import fancyBearStakingABI from '../abis/fancyBearStaking.abi.json';
import fancyBearTraitsABI from '../abis/fancyBearTraits.abi.json';
import fancyBearTraitSaleABI from '../abis/fancyBearTraitSale.abi.json';
import fancyBearTraitSaleMigratedABI from '../abis/fancyBearTraitSale_migrated.abi.json';
import fancyTraitV2SaleABI from '../abis/fancyTraitV2Sale.abi.json';
import fancyTraitV2SaleMigratedABI from '../abis/fancyTraitV2Sale_migrated.abi.json';
import hiveABI from '../abis/hive.abi.json';
import honeyABI from '../abis/honey.abi.json';
import honeyJarsABI from '../abis/honeyJars.abi.json';
import honeyVestingABI from '../abis/honeyVesting.abi.json';
import levelsABI from '../abis/levels.abi.json';
import staticNftsABI from '../abis/staticNft.abi.json';
import traitsABI from '../abis/traits.abi.json';
import traitCategoriesABI from '../abis/traitsCategories.abi.json';
import traitStagingABI from '../abis/traitStaking.abi.json';
import traitSwapABI from '../abis/traitSwap.abi.json';
import { Currency } from '../types';
import { Config } from '../types/config';
import FeatureFlag from '../types/featureFlag';
import { isFeatureEnabled } from '../utils/featueFlagsUtils';
import sepolia from './chains/sepolia';

const allowedChains: Record<string, Chain> = {
  mainnet: chain.mainnet,
  sepolia,
};

const config: Config = {
  environment: process.env.NODE_ENV as Config['environment'],

  chains: [allowedChains[process.env.REACT_APP_CHAIN_NAME!]],

  infura: {
    infuraId: process.env.REACT_APP_INFURA_ID!,
    rpcUrl: process.env.REACT_APP_INFURA_RPC_URL!,
  },

  sentry: {
    sentryDsn: process.env.REACT_APP_SENTRY_DSN!,
  },

  ga: {
    gaId: process.env.REACT_APP_GOOGLE_ANALYTICS_ID!,
  },

  contracts: {
    staticNftContract: {
      address: process.env.REACT_APP_STATIC_NFT_ADDRESS!,
      interface: staticNftsABI,
    },
    dynamicNftContract: {
      address: process.env.REACT_APP_DYNAMIC_NFT_ADDRESS!,
      interface: dynamicNftsABI,
    },
    fancyBearsContract: {
      address: process.env.REACT_APP_FANCY_BEARS_ADDRESS!,
      interface: fancyBearsABI,
    },
    fancyBearTraitsContract: {
      address: process.env.REACT_APP_FANCY_BEAR_TRAITS_ADDRESS!,
      interface: fancyBearTraitsABI,
    },
    fancyBearTraitSaleContract: {
      address: process.env.REACT_APP_FANCY_BEAR_TRAIT_SALE_ADDRESS!,
      interface: isFeatureEnabled(FeatureFlag.MigratedContracts)
        ? fancyBearTraitSaleMigratedABI
        : fancyBearTraitSaleABI,
    },
    honeyJarsContract: {
      address: process.env.REACT_APP_HONEY_JARS_ADDRESS!,
      interface: honeyJarsABI,
    },
    honeyTokenContract: {
      address: process.env.REACT_APP_HONEY_TOKEN_ADDRESS!,
      interface: honeyABI,
    },
    honeyVestingContract: {
      address: process.env.REACT_APP_HONEY_VESTING_ADDRESS!,
      interface: honeyVestingABI,
    },
    traitsContract: {
      address: '',
      interface: traitsABI,
    },
    traitSaleContract: {
      address: '',
      interface: isFeatureEnabled(FeatureFlag.MigratedContracts)
        ? fancyTraitV2SaleMigratedABI
        : fancyTraitV2SaleABI,
    },
    traitSwapContract: {
      address: process.env.REACT_APP_TRAIT_SWAP_ADDRESS!,
      interface: traitSwapABI,
    },
    traitCategoriesContract: {
      address: process.env.REACT_APP_TRAITS_CATEGORIES_ADDRESS!,
      interface: traitCategoriesABI,
    },
    dynamicNftSaleContract: {
      address: process.env.REACT_APP_DYNAMIC_NFT_SALE_ADDRESS!,
      interface: dynamicNftSaleABI,
    },
    hiveContract: {
      address: process.env.REACT_APP_HIVE_ADDRESS!,
      interface: hiveABI,
    },
    traitStakingContract: {
      address: process.env.REACT_APP_TRAIT_STAKING_ADDRESS!,
      interface: traitStagingABI,
    },
    fancyBearStakingContract: {
      address: process.env.REACT_APP_FANCY_BEAR_STAKING_ADDRESS!,
      interface: fancyBearStakingABI,
    },
    levelsContract: {
      address: process.env.REACT_APP_LEVELS_ADDRESS!,
      interface: levelsABI,
    },
    erc20Contract: {
      address: process.env.REACT_APP_ERC20_TOKEN_ADDRESS!,
      interface: erc20ABI,
    },
  },

  currencies: {
    defaultCurrency: process.env.REACT_APP_DEFAULT_CURRENCY! as Currency,
  },

  staticNft: {
    staticNftName: process.env.REACT_APP_STATIC_NFT_NAME!,
    staticNftTotalSupply: parseInt(
      process.env.REACT_APP_STATIC_NFT_TOTAL_SUPPLY!,
      10
    ),
  },

  dynamicNft: {
    dynamicNftName: process.env.REACT_APP_DYNAMIC_NFT_NAME!,
    dynamicNftCollection: {
      collectionId: process.env.REACT_APP_DYNAMIC_NFT_COLLECTION_ID!,
      name: process.env.REACT_APP_DYNAMIC_NFT_COLLECTION_NAME!,
      contractAddress: process.env.REACT_APP_DYNAMIC_NFT_ADDRESS!,
    },
    reserveWalletAddress:
      process.env.REACT_APP_RESERVE_DYNAMIC_NFT_WALLET_ADDRESS!,
    reservePrice: parseEther(
      process.env.REACT_APP_DYNAMIC_NFT_SALE_RESERVE_PRICE!
    ),
    reservedSalesCount: parseInt(
      process.env.REACT_APP_DYNAMIC_NFT_SALES_COUNT_OVERRIDE!,
      10
    ),
    fancyBearHolderSaleDiscount: parseInt(
      process.env.REACT_APP_DYNAMIC_NFT_SALE_BEAR_HOLDER_DISCOUNT!,
      10
    ),
  },

  fancyBears: {
    fancyBearsTotalSupply: parseInt(
      process.env.REACT_APP_FANCY_BEARS_TOTAL_SUPPLY!,
      10
    ),
  },

  erc20: {
    tokenName: process.env.REACT_APP_ERC20_TOKEN_NAME! as Currency,
  },

  traits: {
    defaultTraitCollectionId:
      process.env.REACT_APP_DEFAULT_TRAIT_COLLECTION_ID!,
    traitCategoriesIds: process.env.REACT_APP_TRAIT_CATEGORIES_IDS!.split(','),
    traitFileTagsRenderOrder: process.env
      .REACT_APP_TRAIT_FILE_RENDER_TAGS_ORDER!.split(',')
      .map(renderTag => {
        const [category, fileTag] = renderTag.split(':');
        return { category, fileTag };
      }),
  },

  urls: {
    publicUrl: process.env.PUBLIC_URL!,
    traitswapApiUrl: process.env.REACT_APP_TRAITSWAP_API_URL!,
    fancyBearStakingApiUrl: process.env.REACT_APP_FANCY_BEAR_STAKING_API_URL!,
    staticNftMetadataUrl: process.env.REACT_APP_STATIC_NFT_METADATA_URL!,
    dynamicNftMetadataUrl: process.env.REACT_APP_DYNAMIC_NFT_METADATA_URL!,
    staticNftImagesUrl: process.env.REACT_APP_STATIC_NFT_IMAGES_URL!,
    dynamicNftImagesUrl: process.env.REACT_APP_DYNAMIC_NFT_IMAGES_URL!,
    fancyBearImagesUrl: process.env.REACT_APP_FANCY_BEAR_IMAGES_URL!,

    honeyUniswapUrl: process.env.REACT_APP_HONEY_UNISWAP_URL!,
    ethUniswapUrl: process.env.REACT_APP_ETH_UNISWAP_URL!,
    erc20UniswapUrl: process.env.REACT_APP_ERC20_UNISWAP_URL!,

    staticNftOpenSeaUrl: process.env.REACT_APP_STATIC_NFT_OPENSEA_URL!,
    dynamicNftOpenSeaUrl: process.env.REACT_APP_DYNAMIC_NFT_OPENSEA_URL!,
    honeyJarsOpenSeaUrl: process.env.REACT_APP_HONEY_JARS_OPENSEA_URL!,
    fancyBearsOpenSeaUrl: process.env.REACT_APP_FANCY_BEARS_OPENSEA_URL!,
    openSeaAssetBaseUrl: process.env.REACT_APP_OPENSEA_ASSET_BASE_URL!,
    learnMoreUrl: process.env.REACT_APP_LEARN_MORE_URL!,
    discordUrl: process.env.REACT_APP_DISCORD_URL!,
    twitterUrl: process.env.REACT_APP_TWITTER_URL!,
  },
};

export default config;
