import NftLockedHoney from '../types/NftLockedHoney';
import useAllOwnedDynamicNftsTokenIdsUtil from './useAllOwnedDynamicNftsTokenIdsUtil';
import useDynamicNftCollectionContext from './useDynamicNftCollectionContext';
import useHiveContext from './useHiveContext';
import useVestingContext from './useVestingContext';
import { BigNumber } from 'ethers';
import { useMemo } from 'react';

const useDynamicNftsWithHoneyInVestingOrHiveUtil = () => {
  const allDynamicNfts = useAllOwnedDynamicNftsTokenIdsUtil();
  const { isFancyBearsCollection } = useDynamicNftCollectionContext();
  const { hiveBalanceInDynamicNfts } = useHiveContext();
  const { vestingAmountInBearsRemaining } = useVestingContext();

  const dynamicNftsWithVestedHoney: [
    tokenId: string,
    honeyAmount: BigNumber
  ][] = useMemo(
    () =>
      isFancyBearsCollection && vestingAmountInBearsRemaining
        ? Object.entries(vestingAmountInBearsRemaining).filter(
            ([, honeyAmount]) => !honeyAmount.isZero()
          )
        : [],
    [vestingAmountInBearsRemaining, isFancyBearsCollection]
  );

  const dynamicNftsWithHiveHoney: [tokenId: string, honeyAmount: BigNumber][] =
    useMemo(
      () =>
        hiveBalanceInDynamicNfts
          ? Object.entries(hiveBalanceInDynamicNfts).filter(
              ([tokenId, honeyAmount]) => {
                if (!honeyAmount.isZero()) {
                  return true;
                }

                return allDynamicNfts?.includes(parseInt(tokenId, 10));
              }
            )
          : [],
      [hiveBalanceInDynamicNfts, allDynamicNfts]
    );

  const dynamicNftsWithHoney = useMemo(() => {
    const dynamicNfts: Record<string, NftLockedHoney> = {};

    dynamicNftsWithVestedHoney.forEach(([tokenId, amount]) => {
      dynamicNfts[tokenId] = {
        vestedHoneyAmount: amount,
      };
    });

    dynamicNftsWithHiveHoney.forEach(([tokenId, amount]) => {
      dynamicNfts[tokenId] = {
        ...(dynamicNfts[tokenId] || {}),
        honeyInHiveAmount: amount,
      };
    });

    return Object.entries(dynamicNfts).map(
      ([tokenId, amounts]) =>
        [parseInt(tokenId, 10), amounts] as [number, NftLockedHoney]
    );
  }, [dynamicNftsWithVestedHoney, dynamicNftsWithHiveHoney]);

  return dynamicNftsWithHoney;
};

export default useDynamicNftsWithHoneyInVestingOrHiveUtil;
