import config from '../constants/baseConfig';
import { Trait } from '../types';
import Category from '../types/category';
import { TraitFile, TraitFilePaths } from '../types/trait';

export const getFilesToRenderForTraits = (
  traitIds: string[],
  traitsById: Record<string, Trait>,
  categoriesById: Record<string, Category>
): TraitFilePaths[] => {
  const traits = traitIds.map(traitId => traitsById[traitId]);

  const selectedCategories = traits.map(i => i.category);
  const notSelectedCategoriesRenderTags = Object.keys(categoriesById)
    .filter(categoryId => !selectedCategories.includes(categoryId))
    .flatMap(categoryId => categoriesById[categoryId].notSelectedRenderTags);

  let renderTags: Set<string> = new Set(notSelectedCategoriesRenderTags);
  traits.forEach(trait => {
    renderTags = new Set([...renderTags, ...trait.renderTags]);
  });

  const result = config.traits.traitFileTagsRenderOrder.reduce<
    TraitFilePaths[]
  >((prev, renderTagOrder) => {
    const file = traits
      .reduce<TraitFile[]>(
        (prev, trait) =>
          trait.category === renderTagOrder.category
            ? [...prev, ...trait.files]
            : prev,
        []
      )
      .find(
        file =>
          file.fileTag === renderTagOrder.fileTag &&
          (file.requiredRenderTags.length === 0 ||
            file.requiredRenderTags.every(tag => renderTags.has(tag)))
      );

    return file ? [...prev, file.paths] : prev;
  }, []);

  return result;
};
