import { BigNumber } from 'ethers';
import { useMemo } from 'react';
import FeatureFlag from '../types/featureFlag';
import { isFeatureEnabled } from '../utils/featueFlagsUtils';
import useHiveContext from './useHiveContext';
import useVestingContext from './useVestingContext';
import useWalletAssetsContext from './useWalletAssetsContext';

const useHoneyOwnedTotalUtil = () => {
  const { honeyBalance } = useWalletAssetsContext();
  const { vestingAmountInJarsRemainingSum, vestingAmountInBearsRemainingSum } =
    useVestingContext();
  const { hiveBalanceSum } = useHiveContext();

  return useMemo(() => {
    const zero = BigNumber.from('0');

    return (honeyBalance || zero)
      .add(
        isFeatureEnabled(FeatureFlag.VestedHoneyInBearsPurchases)
          ? vestingAmountInBearsRemainingSum || zero
          : zero
      )
      .add(
        isFeatureEnabled(FeatureFlag.VestedHoneyInJarsPurchases)
          ? vestingAmountInJarsRemainingSum || zero
          : zero
      )
      .add(
        isFeatureEnabled(FeatureFlag.HiveHoneyPurchases)
          ? hiveBalanceSum || zero
          : zero
      );
  }, [
    honeyBalance,
    hiveBalanceSum,
    vestingAmountInJarsRemainingSum,
    vestingAmountInBearsRemainingSum,
  ]);
};

export default useHoneyOwnedTotalUtil;
