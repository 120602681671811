import { Chain } from 'wagmi';

const sepolia = {
  id: 11_155_111,
  network: 'sepolia',
  name: 'Sepolia',
  nativeCurrency: { name: 'Sepolia Ether', symbol: 'SEP', decimals: 18 },
  rpcUrls: ['https://rpc.sepolia.org'],
  blockExplorers: [
    {
      name: 'Etherscan',
      url: 'https://sepolia.etherscan.io',
    },
  ],
  testnet: true,
} as Chain;

export default sepolia;
