import FeatureFlag from '../types/featureFlag';
import IncentivesPopup from './IncentivesPopup';
import Logo from './Logo';
import MainMenu from './MainMenu';
import Panel from './Panel';
import React from 'react';
import useConnectedAccount from '../hooks/useConnectedAccount';
import useTranslate from '../hooks/useTranslate';
import WalletAssetsContainer from '../containers/WalletAssetsContainer';
import WalletPopup from './WalletPopup';
import { FaGift, FaWallet } from 'react-icons/fa';
import { getShortenedAddress } from '../utils/walletUtils';
import { isFeatureEnabled } from '../utils/featueFlagsUtils';
import { Link } from 'react-router-dom';
import {
  Button,
  Container,
  HStack,
  Icon,
  useBoolean,
  VStack,
} from '@chakra-ui/react';

const Header: React.FC = () => {
  const translate = useTranslate();
  const [walletAddress] = useConnectedAccount();
  const [isWalletDialogVisible, setIsWalletDialogVisible] = useBoolean();
  const [isIncentivesPopupOpen, setIsIncentivesPopupOpen] = useBoolean(false);

  return (
    <>
      <Panel as="header" px="0" pt="6" pb="0" borderRadius="0">
        <Container>
          <VStack align="stretch" spacing="6">
            <HStack overflow="hidden" spacing="3">
              <Link to="/">
                <Logo w="9em" />
              </Link>

              <HStack justify="flex-end" w="full" spacing="3">
                {isFeatureEnabled(FeatureFlag.DynamicNftSale) && (
                  <Button
                    flex="none"
                    color="bright.50"
                    borderColor="dark.500"
                    _hover={{
                      borderColor: 'primary.500',
                    }}
                    borderStyle="dashed"
                    colorScheme="bright"
                    borderRadius="full"
                    variant="outline"
                    size="sm"
                    ml="100%"
                    leftIcon={<Icon as={FaGift} color="primary.500" />}
                    onClick={setIsIncentivesPopupOpen.on}
                  >
                    {translate('common:incentives')}
                  </Button>
                )}

                {walletAddress && (
                  <Button
                    flex="none"
                    color="bright.50"
                    borderColor="dark.500"
                    _hover={{
                      borderColor: 'primary.500',
                    }}
                    colorScheme="bright"
                    borderRadius="full"
                    variant="outline"
                    size="sm"
                    leftIcon={
                      isFeatureEnabled(
                        FeatureFlag.DynamicNftSale
                      ) ? undefined : (
                        <Icon as={FaWallet} color="primary.500" />
                      )
                    }
                    onClick={setIsWalletDialogVisible.on}
                  >
                    {getShortenedAddress(walletAddress, 3)}
                  </Button>
                )}
              </HStack>
            </HStack>

            <VStack
              spacing="3"
              w="full"
              align="stretch"
              pb={
                isFeatureEnabled(FeatureFlag.TraitsBuying) ||
                isFeatureEnabled(FeatureFlag.TraitsStaking)
                  ? '0'
                  : '6'
              }
            >
              <WalletAssetsContainer />
              {isFeatureEnabled(FeatureFlag.TraitsBuying) ||
              isFeatureEnabled(FeatureFlag.TraitsStaking) ? (
                <MainMenu />
              ) : null}
            </VStack>
          </VStack>
        </Container>
      </Panel>

      {isWalletDialogVisible && (
        <WalletPopup isOpen onClose={setIsWalletDialogVisible.off} />
      )}

      {isIncentivesPopupOpen && (
        <IncentivesPopup
          isOpen
          onClose={() => setIsIncentivesPopupOpen.off()}
        />
      )}
    </>
  );
};

export default Header;
