import AnimatedPage from '../components/AnimatedPage';
import BoldSpinner from '../components/BoldSpinner';
import config from '../constants/baseConfig';
import DynamicNftAttributesContainer from '../containers/DynamicNftAttributesContainer';
import DynamicNftImage from '../components/DynamicNftImage';
import DynamicNftSelectorContainer from '../containers/DynamicNftSelectorContainer';
import FancyBearHoneyStatsContainer from '../containers/FancyBearHoneyStatsContainer';
import FancyBearLevelContainer from '../containers/FancyBearLevelContainer';
import FeatureFlag from '../types/featureFlag';
import Panel from '../components/Panel';
import StakedFancyBearTraitsContainer from '../containers/StakedFancyBearTraitsContainer';
import StakedTraitsContainer from '../containers/StakedTraitsContainer';
import useDynamicNftCollectionContext from '../hooks/useDynamicNftCollectionContext';
import useDynamicNftContext from '../hooks/useDynamicNftContext';
import usePageTracking from '../hooks/usePageTracking';
import { FaChevronLeft, FaExternalLinkAlt } from 'react-icons/fa';
import { isFeatureEnabled } from '../utils/featueFlagsUtils';
import { Link } from 'react-router-dom';
import {
  Box,
  Button,
  ButtonProps,
  Center,
  Container,
  HStack,
  Icon,
  IconButton,
  Show,
  VStack,
} from '@chakra-ui/react';

const NftDetailsPage = () => {
  const { isFancyBearsCollection } = useDynamicNftCollectionContext();
  const { dynamicNft, isLoading } = useDynamicNftContext();

  const osButtonProps: ButtonProps = {
    as: 'a',
    // @ts-ignore
    href: `${config.urls.openSeaAssetBaseUrl}/${config.contracts.dynamicNftContract.address}/${dynamicNft.tokenId}`,
    target: '_blank',
    rel: 'noreferrer',
    cursor: 'pointer',
    colorScheme: 'dark',
    variant: 'outline',
    size: 'sm',
  };

  usePageTracking();

  if (isLoading) {
    return (
      <Center h="50vh">
        <BoldSpinner size="xl" />
      </Center>
    );
  }

  return (
    <AnimatedPage>
      <Container pt="12" pb="16">
        <VStack spacing="3" align="flex-start">
          <Box w="full">
            <Panel>
              <VStack spacing="3">
                <HStack justify="space-between" w="full" p="1.5" pl="0">
                  <HStack spacing="1.5" justify="center">
                    <Link to="/nfts" style={{ lineHeight: '0' }}>
                      <IconButton
                        icon={<Icon as={FaChevronLeft} w="5" h="auto" />}
                        variant="link"
                        aria-label=""
                        size="sm"
                      />
                    </Link>

                    <DynamicNftSelectorContainer path="details" />
                  </HStack>

                  <Show above="sm">
                    <Button
                      {...osButtonProps}
                      rightIcon={<Icon as={FaExternalLinkAlt} />}
                    >
                      OpenSea
                    </Button>
                  </Show>
                  <Show below="sm">
                    <IconButton
                      {...osButtonProps}
                      icon={<Icon as={FaExternalLinkAlt} />}
                      aria-label="OpenSea"
                      borderRadius="full"
                      variant="solid"
                    />
                  </Show>
                </HStack>

                <DynamicNftImage tokenId={dynamicNft.tokenId} size="512" />

                {isFancyBearsCollection && <FancyBearLevelContainer />}
                {isFancyBearsCollection && <FancyBearHoneyStatsContainer />}
              </VStack>
            </Panel>
          </Box>

          <DynamicNftAttributesContainer />

          {isFeatureEnabled(FeatureFlag.TraitsUnstaking) && (
            <StakedTraitsContainer />
          )}

          {isFeatureEnabled(FeatureFlag.TraitsUnstaking) &&
            isFeatureEnabled(FeatureFlag.DeprecatedTraitsIUnstaking) &&
            isFancyBearsCollection && <StakedFancyBearTraitsContainer />}
        </VStack>
      </Container>
    </AnimatedPage>
  );
};

export default NftDetailsPage;
