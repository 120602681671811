import config from '../../constants/baseConfig';
import useBigNumbersSumUtil from '../useBigNumbersSumUtil';
import { BigNumber } from 'ethers';
import { useContractRead } from 'wagmi';
import { useEffect, useState } from 'react';
// import { useCallback, useEffect, useState } from 'react';

// import { BigNumber } from 'ethers';
// import config from '../../constants/baseConfig';
// import useBigNumbersSumUtil from '../useBigNumbersSumUtil';
// import { useContractRead } from 'wagmi';

// type VestingAmountRemainingsResult = [BigNumber[], BigNumber[]];
// type VestingAmountRemainingsByTokenIds = Record<number, BigNumber>;

// const useVestingAmountRemainingByWalletInBearsContractCall = (
//   walletAddress?: string,
//   ownedBearsTokenIds?: number[]
// ): [
//   remaining: Record<number, BigNumber> | undefined,
//   remainingSum: BigNumber | undefined,
//   readVestingAmountRemaining: () => void
// ] => {
//   const [remaining, setRemaining] =
//     useState<VestingAmountRemainingsByTokenIds>();
//   const remainingSum = useBigNumbersSumUtil(
//     remaining ? Object.values(remaining) : []
//   );

//   const [{ data: remainingInWalletData }, readVestingAmountRemainingInWallet] =
//     useContractRead(
//       {
//         addressOrName: config.contracts.honeyVestingContract.address,
//         contractInterface: config.contracts.honeyVestingContract.interface,
//       },
//       'getVestingAmountRemainingInBearsByWallet',
//       {
//         args: walletAddress,
//       }
//     );

//   const [{ data: remainngInStakingData }, readVestingAmountRemainingInStaking] =
//     useContractRead(
//       {
//         addressOrName: config.contracts.honeyVestingContract.address,
//         contractInterface: config.contracts.honeyVestingContract.interface,
//       },
//       'getVestingAmountRemainingInBearsByWallet',
//       {
//         args: config.contracts.fancyBearStakingContract.address,
//       }
//     );

//   const readVestingAmountRemaining = useCallback(() => {
//     readVestingAmountRemainingInWallet();
//     readVestingAmountRemainingInStaking();
//   }, [readVestingAmountRemainingInWallet, readVestingAmountRemainingInStaking]);

//   useEffect(() => {
//     if (
//       remainingInWalletData !== undefined &&
//       remainngInStakingData !== undefined &&
//       ownedBearsTokenIds?.length
//     ) {
//       const remainingsInWallet: VestingAmountRemainingsByTokenIds = (
//         remainingInWalletData as VestingAmountRemainingsResult
//       )[1].reduce(
//         (acc, tokenId, i) => ({
//           ...acc,
//           [tokenId.toNumber()]: (
//             remainingInWalletData as VestingAmountRemainingsResult
//           )[0][i],
//         }),
//         {}
//       );

//       const remainingsInStaking: VestingAmountRemainingsByTokenIds = (
//         remainngInStakingData as VestingAmountRemainingsResult
//       )[1].reduce(
//         (acc, tokenId, i) => ({
//           ...acc,
//           [tokenId.toNumber()]: (
//             remainngInStakingData as VestingAmountRemainingsResult
//           )[0][i],
//         }),
//         {}
//       );

//       const allRemainings: VestingAmountRemainingsByTokenIds =
//         ownedBearsTokenIds.reduce((acc, tokenId) => {
//           if (remainingsInWallet[tokenId] || remainingsInStaking[tokenId]) {
//             return {
//               ...acc,
//               [tokenId]:
//                 remainingsInWallet[tokenId] || remainingsInStaking[tokenId],
//             };
//           }

//           return acc;
//         }, {});

//       setRemaining(allRemainings);
//     }
//   }, [remainingInWalletData, remainngInStakingData, ownedBearsTokenIds]);

//   return [remaining, remainingSum, readVestingAmountRemaining];
// };

// export default useVestingAmountRemainingByWalletInBearsContractCall;

const useVestingAmountRemainingByWalletInBearsContractCall = (
  walletAddress?: string,
  skip = false
): [
  remaining: Record<number, BigNumber> | undefined,
  remainingSum: BigNumber | undefined,
  readVestingAmountRemaining: () => void
] => {
  const [remaining, setRemaining] = useState<Record<number, BigNumber>>();
  const remainingSum = useBigNumbersSumUtil(
    remaining ? Object.values(remaining) : []
  );

  const [{ data: remainingData }, readVestingAmountRemaining] = useContractRead(
    {
      addressOrName: config.contracts.honeyVestingContract.address,
      contractInterface: config.contracts.honeyVestingContract.interface,
    },
    'getVestingAmountRemainingInBearsByWallet',
    {
      args: walletAddress,
      skip,
    }
  );

  useEffect(() => {
    if (remainingData !== undefined) {
      const data = remainingData as [BigNumber[], BigNumber[]];

      setRemaining(
        data[1].reduce(
          (prev, tokenId, i) => ({
            ...prev,
            [tokenId.toNumber()]: data[0][i],
          }),
          {}
        ) as Record<number, BigNumber>
      );
    }
  }, [remainingData]);

  return [remaining, remainingSum, readVestingAmountRemaining];
};

export default useVestingAmountRemainingByWalletInBearsContractCall;
