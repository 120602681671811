import { BigNumber } from 'ethers';
import { useEffect, useMemo } from 'react';
import { useContractWrite, useWaitForTransaction } from 'wagmi';
import config from '../../constants/baseConfig';
import { getLevelAttribution } from '../../utils/purchaseUtils';
import useCaptureRpcException from '../useCaptureRpcException';
import useDynamicNftCollectionContext from '../useDynamicNftCollectionContext';
import useSnackbarRpcError from '../useSnackbarRpcError';
import useSnackbarRpcSuccess from '../useSnackbarRpcSuccess';
import useTraitCollectionContext from '../useTraitCollectionContext';
/* eslint-disable react-hooks/exhaustive-deps */

export type V2TraitsPurchaseEthContractCallData = {
  traitTokenIds: number[];
  amountPerTrait: number[];
};

const useV2TraitsPurchaseWithEthContractCall = (
  selectedTokenId?: number,
  traitTokenIds?: number[],
  totalPrice?: BigNumber,
  onSuccess?: () => void
): [() => void, boolean | undefined] => {
  const { traitCollection } = useTraitCollectionContext();
  const { dynamicNftCollection } = useDynamicNftCollectionContext();

  const args = useMemo(() => {
    if (!traitTokenIds?.length) {
      return;
    }

    const amountPerTrait = new Array(traitTokenIds?.length || 0).fill(1);

    const levelAttribution = getLevelAttribution(
      selectedTokenId,
      dynamicNftCollection.contractAddress
    );

    return [traitTokenIds, amountPerTrait, levelAttribution];
  }, [selectedTokenId, traitTokenIds, dynamicNftCollection.contractAddress]);

  const [{ data, loading: isLoading, error: writeError }, purchase] =
    useContractWrite(
      {
        addressOrName: traitCollection.saleContractAddress,
        contractInterface: config.contracts.traitSaleContract.interface,
      },
      'purchaseTraitsWithETH',
      {
        args,
        overrides: {
          value: totalPrice,
        },
      }
    );

  const [{ data: waitData, loading: isWaiting, error: waitError }] =
    useWaitForTransaction({ confirmations: 1, wait: data?.wait });

  useSnackbarRpcError(writeError);
  useSnackbarRpcError(waitError);
  useSnackbarRpcSuccess(waitData);

  useCaptureRpcException(waitError);
  useCaptureRpcException(writeError);

  console.log(
    traitCollection.saleContractAddress,
    'purchaseTraitsWithETH',
    args
  );

  console.log('purchaseTraitsWithETH', writeError);
  console.log('purchaseTraitsWithETH', waitError);

  useEffect(() => {
    if (!isWaiting && waitData) {
      onSuccess?.();
    }
  }, [isWaiting, waitData]);

  return [purchase, isLoading || isWaiting];
};

export default useV2TraitsPurchaseWithEthContractCall;
