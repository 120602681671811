import DynamicNftExistsGuard from '../guards/DynamicNftExistsGuard';
import DynamicNftProvider from '../providers/DynamicNftProvider';
import DynamicNftRedirect from '../guards/DynamicNftRedirect';
import FeatureFlag from '../types/featureFlag';
import Header from '../components/Header';
import Layout from '../components/Layout';
import NetworkErrorsContainer from '../containers/NetworkErrorsContainer';
import NftDetailsPage from './NftDetailsPage';
import NftsPage from '../pages/NftsPage';
import NotFoundMessage from '../components/NotFoundMessage';
import StorePage from './StorePage';
import WardrobePage from './WardrobePage';
import { AnimatePresence } from 'framer-motion';
import { Center } from '@chakra-ui/react';
import { isFeatureEnabled } from '../utils/featueFlagsUtils';
import {
  Navigate,
  Outlet,
  Route,
  Routes
  } from 'react-router-dom';
import { useMemo } from 'react';

const ConnectedPage = () => {
  const v2Routes = useMemo(() => {
    const routes = [
      {
        path: 'details',
        component: NftDetailsPage,
      },
    ];

    if (isFeatureEnabled(FeatureFlag.TraitsBuying)) {
      routes.push({
        path: 'store',
        component: StorePage,
      });
    }

    if (isFeatureEnabled(FeatureFlag.TraitsStaking)) {
      routes.push({
        path: 'wardrobe',
        component: WardrobePage,
      });
    }

    return routes;
  }, []);

  return (
    <Layout>
      <Header />

      <AnimatePresence exitBeforeEnter>
        <Routes>
          <Route path="/" element={<Navigate to="/nfts" replace />} />

          <Route path="nfts" element={<NftsPage />} />

          <Route path="nft" element={<DynamicNftRedirect />} />

          <Route
            path="nft/:tokenId"
            element={
              <DynamicNftExistsGuard
                yeyElement={
                  <DynamicNftProvider>
                    <Outlet />
                  </DynamicNftProvider>
                }
                neyElement={
                  <Center pt="12" pb="16">
                    <NotFoundMessage />
                  </Center>
                }
              />
            }
          >
            {v2Routes.map(({ path, component: Component }) => (
              <Route key={path} path={path} element={<Component />} />
            ))}
          </Route>

          <Route
            path="*"
            element={
              <Center pt="12" pb="16">
                <NotFoundMessage />
              </Center>
            }
          />
        </Routes>
      </AnimatePresence>

      <NetworkErrorsContainer />
    </Layout>
  );
};

export default ConnectedPage;
