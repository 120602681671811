import DynamicNftIcon from './DynamicNftIcon';
import FeatureFlag from '../types/featureFlag';
import React, { useMemo } from 'react';
import StoreIcon from './StoreIcon';
import Tab from './Tab';
import useLastDynamicTokenIdUtil from '../hooks/useLastDynamicTokenIdUtil';
import useTranslate from '../hooks/useTranslate';
import WardrobeIcon from './WardrobeIcon';
import { Icon, SimpleGrid } from '@chakra-ui/react';
import { isFeatureEnabled } from '../utils/featueFlagsUtils';
import { NavLink } from 'react-router-dom';

const MainMenu: React.FC = () => {
  const translate = useTranslate();

  const lastDynamicNftTokenId = useLastDynamicTokenIdUtil();

  const menuLinks = useMemo(() => {
    const links = [
      {
        to: '/nfts',
        key: 'mainMenu:nfts',
        icon: DynamicNftIcon,
      },
    ];

    if (isFeatureEnabled(FeatureFlag.TraitsBuying)) {
      links.push({
        to: `/nft/${lastDynamicNftTokenId}/store`,
        key: 'mainMenu:store',
        icon: StoreIcon,
      });
    }

    if (isFeatureEnabled(FeatureFlag.TraitsStaking)) {
      links.push({
        to: `/nft/${lastDynamicNftTokenId}/wardrobe`,
        key: 'mainMenu:wardrobe',
        icon: WardrobeIcon,
      });
    }

    return links;
  }, [lastDynamicNftTokenId]);

  return (
    <SimpleGrid columns={3}>
      {menuLinks.map(({ to, key, icon }) => (
        <NavLink key={to} to={to}>
          {({ isActive }) => (
            <Tab
              w="full"
              leftIcon={
                <Icon
                  as={icon}
                  w={{
                    base: '5',
                    sm: '6',
                  }}
                  h="auto"
                />
              }
              size="lg"
              isActive={isActive}
            >
              {translate(key)}
            </Tab>
          )}
        </NavLink>
      ))}
    </SimpleGrid>
  );
};

export default MainMenu;
