import { Box, Button, ModalProps, VStack } from '@chakra-ui/react';
import config from '../constants/baseConfig';
import useHiveContext from '../hooks/useHiveContext';
import useTranslate from '../hooks/useTranslate';
import useVestingContext from '../hooks/useVestingContext';
import useWalletAssetsContext from '../hooks/useWalletAssetsContext';
import FeatureFlag from '../types/featureFlag';
import { isFeatureEnabled } from '../utils/featueFlagsUtils';
import EtherAmount from './EtherAmount';
import HoneyIcon from './HoneyIcon';
import SimplePopup from './SimplePopup';
import WalletAsset from './WalletAsset';

type HoneyBalancePopupProps = Omit<ModalProps, 'children'>;

const HoneyBalancePopup = (props: HoneyBalancePopupProps) => {
  const translate = useTranslate();
  const { honeyBalance } = useWalletAssetsContext();
  const { vestingAmountInJarsRemainingSum, vestingAmountInBearsRemainingSum } =
    useVestingContext();
  const { hiveBalanceSum } = useHiveContext();

  return (
    <SimplePopup title={translate('honeyPopup:title')} size="sm" {...props}>
      <VStack spacing="2px" mt="6" pb="2">
        <WalletAsset
          icon={HoneyIcon}
          title={translate('honeyPopup:honeyInWallet:title')}
          value={<EtherAmount amount={honeyBalance} />}
        >
          {config.urls.honeyUniswapUrl && (
            <Box pr="3">
              <Button
                as="a"
                href={config.urls.honeyUniswapUrl}
                target="_blank"
                size="xs"
                variant="outline"
              >
                {translate('common:buy')}
              </Button>
            </Box>
          )}
        </WalletAsset>

        {isFeatureEnabled(FeatureFlag.HiveHoneyPurchases) && (
          <WalletAsset
            icon={HoneyIcon}
            title={translate('honeyPopup:honeyInHive:title')}
            value={<EtherAmount amount={hiveBalanceSum} />}
          />
        )}

        {isFeatureEnabled(FeatureFlag.VestedHoneyInJarsPurchases) && (
          <WalletAsset
            icon={HoneyIcon}
            title={translate('honeyPopup:honeyInJars:title')}
            value={<EtherAmount amount={vestingAmountInJarsRemainingSum} />}
          />
        )}

        {isFeatureEnabled(FeatureFlag.VestedHoneyInBearsPurchases) && (
          <WalletAsset
            icon={HoneyIcon}
            title={translate('honeyPopup:honeyInBears:title')}
            value={<EtherAmount amount={vestingAmountInBearsRemainingSum} />}
          />
        )}
      </VStack>
    </SimplePopup>
  );
};

export default HoneyBalancePopup;
