import FancyBearTraitsUnstakePopup from '../components/FancyBearTraitsUnstakePopup';
import Panel from '../components/Panel';
import ReactGA from 'react-ga4';
import SeparatedList from '../components/SeparatedList';
import StakeTraitsSuccessPopup from '../components/StakeTraitsSuccessPopup';
import TraitTile from '../components/TraitTile';
import useApplyTraitsUnswapsUtil from '../hooks/useApplyTraitsUnswapsUtil';
import useDynamicNftCollectionContext from '../hooks/useDynamicNftCollectionContext';
import useDynamicNftContext from '../hooks/useDynamicNftContext';
import useFancyBearStakedTraitsContractCall from '../hooks/bc/useFancyBearStakedTraitsContractCall';
import useTranslate from '../hooks/useTranslate';
import useWalletAssetsContext from '../hooks/useWalletAssetsContext';
import { Box, Button, useBoolean, usePrevious } from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';

const StakedFancyBearTraitsContainer = () => {
  const translate = useTranslate();
  const { dynamicNftsInWallet } = useWalletAssetsContext();
  const { dynamicNft } = useDynamicNftContext();
  const lastTokenId = usePrevious(dynamicNft.tokenId);
  const { traitsById } = useDynamicNftCollectionContext();
  const [stakedTraitIds, readStakedTraitIds] =
    useFancyBearStakedTraitsContractCall(dynamicNft.tokenId);

  const [selectedTraitIds, setSelectedTraits] = useState<string[]>();
  const [isUnstakePopupOpen, setIsUnstakePopupOpen] = useBoolean();
  const [isUnstakeSuccessPopupOpen, setIsUnstakeSuccessPopupOpen] =
    useBoolean();
  const hasStakedTraits = (stakedTraitIds?.length || 0) > 0;
  const isMultiple = (stakedTraitIds?.length || 0) > 1;
  const isInWallet = dynamicNftsInWallet?.includes(dynamicNft.tokenId);

  const isOwner = isInWallet;

  const newTraitIds = useApplyTraitsUnswapsUtil(
    dynamicNft.originalTraitIds,
    dynamicNft.traitIds,
    selectedTraitIds
  );

  const handleUnstake = useCallback(
    (traitId: string) => {
      setSelectedTraits([traitId]);
      setIsUnstakePopupOpen.on();
    },
    [setIsUnstakePopupOpen]
  );

  const handleUnstakeAll = useCallback(() => {
    setSelectedTraits(stakedTraitIds);
    setIsUnstakePopupOpen.on();
  }, [setIsUnstakePopupOpen, stakedTraitIds]);

  const handleSuccess = useCallback(() => {
    setIsUnstakePopupOpen.off();
    setIsUnstakeSuccessPopupOpen.on();

    ReactGA.event({
      category: 'Trait Swap',
      action: 'Unstake',
      label: selectedTraitIds?.join(', ') || undefined,
    });
  }, [selectedTraitIds, setIsUnstakePopupOpen, setIsUnstakeSuccessPopupOpen]);

  useEffect(() => {
    if (lastTokenId === undefined) {
      return;
    }
    if (dynamicNft.tokenId !== lastTokenId) {
      readStakedTraitIds();
    }
  }, [dynamicNft, lastTokenId, readStakedTraitIds]);

  const renderItem = useCallback(
    (traitId: string) => {
      const trait = traitsById[traitId];

      if (!trait) {
        return <></>;
      }

      return (
        <TraitTile
          key={traitId}
          traitId={traitId}
          tokenId={trait.tokenId}
          category={trait.category}
          name={trait.name}
        >
          {isInWallet && (
            <Button
              size="sm"
              colorScheme="dark"
              onClick={() => handleUnstake(traitId)}
              mr="1"
            >
              {translate('common:unstake')}
            </Button>
          )}
        </TraitTile>
      );
    },
    [traitsById, handleUnstake, isInWallet, translate]
  );

  return hasStakedTraits ? (
    <Box w="full">
      <Panel
        borderBottomRadius={isOwner && isMultiple ? 'none' : undefined}
        w="full"
      >
        <SeparatedList
          title={translate('stakedFancyBearTraits:title')}
          columns={1}
        >
          {stakedTraitIds?.map(renderItem)}
        </SeparatedList>
      </Panel>

      {isOwner && isMultiple && (
        <Panel pt="0" borderTopRadius="none">
          <Button w="full" onClick={handleUnstakeAll}>
            {translate('stakedTraits:unstakeAll', {
              count: stakedTraitIds?.length,
            })}
          </Button>
        </Panel>
      )}

      {selectedTraitIds && isUnstakePopupOpen && (
        <FancyBearTraitsUnstakePopup
          isOpen
          originalTraitIds={dynamicNft.originalTraitIds}
          currentTraitIds={dynamicNft.traitIds}
          newTraitIds={newTraitIds}
          traitIdsToUnstake={selectedTraitIds}
          dynamicNftTokenId={dynamicNft.tokenId}
          onClose={setIsUnstakePopupOpen.off}
          onSuccess={handleSuccess}
        />
      )}

      {selectedTraitIds && isUnstakeSuccessPopupOpen && (
        <StakeTraitsSuccessPopup
          isOpen
          title={translate('unstake:success:title')}
          description={translate('unstake:success:description')}
          dynamicNftTokenId={dynamicNft.tokenId}
          newTraitIds={newTraitIds}
        />
      )}
    </Box>
  ) : null;
};

export default StakedFancyBearTraitsContainer;
