enum FeatureFlag {
  TraitsStaking = 'TRAITS_STAKING',
  TraitsUnstaking = 'TRAITS_UNSTAKING',
  TraitsBuying = 'TRAITS_BUYING',
  DynamicNftSale = 'DYNAMIC_NFT_SALE',
  HiveHoneyPurchases = 'HIVE_HONEY_PURCHASES',
  VestedHoneyInJarsPurchases = 'VESTED_HONEY_IN_JARS_PURCHASES',
  VestedHoneyInBearsPurchases = 'VESTED_HONEY_IN_BEARS_PURCHASES',
  Erc20Purchases = 'ERC20_PURCHASES',
  DeprecatedTraitsIUnstaking = 'DEPRECATED_TRAITS_STAKING',
  MigratedContracts = 'MIGRATED_CONTRACTS',
}

export default FeatureFlag;
