import { Center } from '@chakra-ui/react';
import { FunctionComponent, useCallback, useMemo, useState } from 'react';
import BoldSpinner from '../components/BoldSpinner';
import config from '../constants/baseConfig';
import DynamicNftCollectionContext, {
  DynamicNftCollectionContextValue,
} from '../contexts/DynamicNftCollectionContext';
import useFetchCategoriesApiCall from '../hooks/api/useFetchCategoriesApiCall';
import useFetchCollectionDynamicNftIdsApiCall from '../hooks/api/useFetchCollectionDynamicNftIdsApiCall';
import useFetchCollectionTraitsApiCall from '../hooks/api/useFetchCollectionTraitsApiCall';
import useFetchTraitCollectionsApiCall from '../hooks/api/useFetchTraitCollectionsApiCall';
import { Trait } from '../types';
import DynamicNftCollection from '../types/dynamicNftCollection';

type DynamicNftCollectionProviderProps = {};

const DynamicNftCollectionProvider: FunctionComponent<
  DynamicNftCollectionProviderProps
> = ({ children }) => {
  const [dynamicNftCollection] = useState<DynamicNftCollection>(
    config.dynamicNft.dynamicNftCollection
  );

  const [dynamicNftTokenIds, fetchDynamicNftIdsPromise, isIdsLoading] =
    useFetchCollectionDynamicNftIdsApiCall(
      dynamicNftCollection.contractAddress
    );

  const [categories, fetchCategoriesPromise, isCategoriesLoading] =
    useFetchCategoriesApiCall(dynamicNftCollection.contractAddress);

  const [
    traitCollections,
    fetchTraitCollectionsPromise,
    isTraitCollectionsLoading,
  ] = useFetchTraitCollectionsApiCall(dynamicNftCollection.contractAddress);

  const [traits, fetchTraitsPromise] = useFetchCollectionTraitsApiCall(
    dynamicNftCollection.contractAddress
  );

  const isFancyBearsCollection =
    dynamicNftCollection?.contractAddress ===
    config.contracts.fancyBearsContract.address;

  const categoryIndexesById = useMemo(() => {
    if (!categories) {
      return {};
    }

    return categories.reduce(
      (prev, category, index) => ({
        ...prev,
        [category.categoryId]: index,
      }),
      {}
    );
  }, [categories]);

  const categoriesById = useMemo(() => {
    if (!categories) {
      return {};
    }

    return categories.reduce(
      (prev, category) => ({
        ...prev,
        [category.categoryId]: category,
      }),
      {}
    );
  }, [categories]);

  const traitsById = useMemo(
    () =>
      traits?.reduce(
        (prev, trait) => ({
          ...prev,
          [trait.traitId]: trait,
        }),
        {}
      ),
    [traits]
  );

  const traitsByCollectionId = useMemo(() => {
    if (traits) {
      return traits.reduce((prev, trait) => {
        return {
          ...prev,
          [trait.traitCollectionId]: [
            ...(prev[trait.traitCollectionId] || []),
            trait,
          ],
        };
      }, {} as Record<string, Trait[]>);
    }

    return {};
  }, [traits]);

  const traitCollectionsById = useMemo(() => {
    if (!traitCollections) {
      return {};
    }

    return traitCollections.reduce(
      (prev, collection) => ({
        ...prev,
        [collection.traitCollectionId]: collection,
      }),
      {}
    );
  }, [traitCollections]);

  const traitCollectionsByAddress = useMemo(() => {
    if (!traitCollections) {
      return {};
    }

    return traitCollections.reduce(
      (prev, collection) => ({
        ...prev,
        [collection.contractAddress]: collection,
      }),
      {}
    );
  }, [traitCollections]);

  const fetchDynamicNftIds = useCallback(() => {
    fetchDynamicNftIdsPromise(dynamicNftCollection.contractAddress);
  }, [dynamicNftCollection.contractAddress, fetchDynamicNftIdsPromise]);

  const fetchCategories = useCallback(() => {
    fetchCategoriesPromise(dynamicNftCollection.contractAddress);
  }, [dynamicNftCollection.contractAddress, fetchCategoriesPromise]);

  const fetchTraitCollections = useCallback(() => {
    fetchTraitCollectionsPromise(dynamicNftCollection.contractAddress);
  }, [dynamicNftCollection.contractAddress, fetchTraitCollectionsPromise]);

  const fetchTraits = useCallback(() => {
    fetchTraitsPromise(dynamicNftCollection.contractAddress);
  }, [dynamicNftCollection.contractAddress, fetchTraitsPromise]);

  const contextValue = useMemo<DynamicNftCollectionContextValue>(
    () => ({
      dynamicNftCollection,
      categories: categories || [],
      categoriesById: categoriesById,
      categoryIndexesById,
      traitCollections: traitCollections || [],
      traitCollectionsById,
      traitCollectionsByAddress,
      traitsByCollectionId,
      dynamicNftTokenIds: dynamicNftTokenIds || [],
      traits: traits || [],
      traitsById: traitsById || {},
      fetchDynamicNftIds,
      fetchCategories,
      fetchTraitCollections,
      fetchTraits,
      isLoading:
        isIdsLoading || isCategoriesLoading || isTraitCollectionsLoading,
      isFancyBearsCollection: isFancyBearsCollection,
    }),
    [
      dynamicNftCollection,
      categories,
      categoriesById,
      categoryIndexesById,
      traitCollections,
      traitCollectionsById,
      traitCollectionsByAddress,
      traitsByCollectionId,
      dynamicNftTokenIds,
      traits,
      traitsById,
      fetchDynamicNftIds,
      fetchCategories,
      fetchTraitCollections,
      fetchTraits,
      isIdsLoading,
      isCategoriesLoading,
      isTraitCollectionsLoading,
      isFancyBearsCollection,
    ]
  );

  return (
    <DynamicNftCollectionContext.Provider value={contextValue}>
      {isIdsLoading || isCategoriesLoading || isTraitCollectionsLoading ? (
        <Center h="100vh">
          <BoldSpinner size="xl" />
        </Center>
      ) : (
        children
      )}
    </DynamicNftCollectionContext.Provider>
  );
};

export default DynamicNftCollectionProvider;
